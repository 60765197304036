import { ui } from './ui';

const images = {
  carreon01nobg: require(`../assets/img/carreon_01_nobg.png`),
  carreon01: require(`../assets/img/carreon_01.jpg`),
  carreon02: require(`../assets/img/carreon_02.jpg`),
  justicebg: require(`../assets/img/justicebg.jpg`),
  logo_512: require(`../assets/img/logo_512.png`),
  logo_fc_hz_color: require(`../assets/img/logo_fc_hz_color.png`),
  logo_fc_sq_color: require(`../assets/img/logo_fc_sq_color.png`),
  logo_fe_hz_color: require(`../assets/img/logo_fe_hz_color.png`),
  logo_fe_sq_color: require(`../assets/img/logo_fe_sq_color.png`),
  autista_terapia: require(`../assets/img/autista-terapia.jpg`),
  direito_digital : require(`../assets/img/direito-digital.jpg`),
  direito_medico: require(`../assets/img/direito-medico.jpg`),
  medico_analisando: require(`../assets/img/medico-analisando.jpg`),
  paciente_atendimento: require(`../assets/img/paciente-atendimento.jpg`),
  reuniao_medicos: require(`../assets/img/reuniao-medicos.jpg`),
};

const colors = {
  black: '#000000',
  darkest: '#02071b',
  darker: '#070d24',
  dark: '#0d142f',
  grafitte: '#393e46',
  blue: '#3a4776',
  bluer: '#007fd4',
  lightBlue: '#007fd4',
  yellow: '#f3c82e',
  gold: '#d1bb57',
  gray: '#cccccc',
  grey: '#f0f0f0',
  white: '#ffffff',
  bs: {
    blue: '#1e337c',
    indigo: '#6610f2',
    purple: '#6f42c1',
    pink: '#d63384',
    red: '#aa0000',
    orange: '#fd7e14',
    yellow: '#ffbb00',
    green: '#2cbe00',
    teal: '#00a372',
    cyan: '#007fd4',
    black: '#000',
    white: '#fff',
    gray: {
      100: '#f8f9fa',
      200: '#e9ecef',
      300: '#dee2e6',
      400: '#ced4da',
      500: '#adb5bd',
      600: '#6c757d',
      700: '#495057',
      800: '#343a40',
      900: '#212529',
    }
  }
};

const conf = {
  tenant: 'carreon',
  brand: 'carreon',
  headline: 'Seu slogan aqui',
  site: 'Carreon Advocacia',
  title: 'Evandro Carreon Advocacia',
  endpoint: 'link API',
  logo: 'logo_512.png',
  favicon: '/assets/img/favicon.ico',
  contato: {
    email: 'evandro@carreon.adv.br',
    telefone: '(11) 99195-7391',
    endereco: 'Av. Paulista, São Paulo-SP',
    whatsapp: '(11) 99195-7391',
    linkedin: 'evandrocarreonadv',
    instagram: 'evandrocarreonadv',
  },
  images,
  colors,
};

const menu = {
  navbar: [
    {
      name: 'home',
      text: 'Home',
      showText: true,
      tooltip: 'Página inicial',
      path: '/',
      icon: ui.misc.house,
      toggle: false,
      setSection: true,
      section: 'home',
      addClass: '',
      onlyLogged: false,
    },
    {
      name: 'menu',
      text: 'Menu',
      showText: false,
      tooltip: 'Menu',
      icon: ui.misc.menu,
      toggle: true,
      setSection: false,
      addClass: '',
      onlyLogged: false,
    },
    {
      name: 'restricted',
      text: 'Área Restrita',
      showText: true,
      tooltip: 'Área Restrita',
      textLogged: 'Área Restrita',
      textUnlogged: 'Login',
      icon: ui.user.lock,
      toggle: false,
      setSection: false,
      addClass: '',
      path: '/app',
      onlyLogged: false,
    },
    {
      name: 'logout',
      text: 'Logout',
      showText: true,
      tooltip: 'Logout',
      icon: ui.misc.doorout,
      toggle: false,
      setSection: false,
      addClass: '',
      path: '/logout',
      onlyLogged: true,
    },
  ],
};

const content = {
  home: {
    title: 'Apresentação do Escritório',
    key: 'carreon_home',
    sections: [
      {
        title:
          'Carreon Advocacia: Excelência Jurídica em Direito Médico, da Saúde e Digital em Todo o Brasil',
        subtitle: null,
        key: 'carreon_apresentacao',
        paragraphs: [
          {
            type: 'p',
            text: 'Nossa história é pautada pela busca incessante por justiça e proteção dos direitos no setor de saúde e no ambiente digital. Com mais de duas décadas de atuação, liderados pelo Dr. Evandro Carreon, trazemos uma abordagem moderna que combina tradição jurídica com inovação tecnológica, sempre focados em soluções humanizadas para nossos clientes.',
          },
          {
            type: 'p',
            text: 'Nosso compromisso é transformar o cenário do Direito Médico, da Saúde e Digital no Brasil, sendo reconhecidos como uma das principais referências nacionais nessas áreas. Através de consultoria especializada e multidisciplinar, nos dedicamos a proporcionar a segurança jurídica necessária para médicos, pacientes e empresas em um mundo cada vez mais conectado e globalizado.',
          },
          {
            type: 'p',
            text: 'Nossa missão é oferecer soluções jurídicas de excelência, atuando em áreas distintas e complexas como o Direito Médico, Direito da Saúde e Direito Digital. Entendemos que cada uma dessas áreas tem particularidades próprias: o Direito Médico protege profissionais de saúde, o Direito da Saúde assegura o acesso dos pacientes a tratamentos e o Direito Digital garante conformidade e segurança no ambiente virtual. Nossa abordagem integra tecnologia, ética e humanização, sempre em busca da justiça e proteção dos direitos de nossos clientes.',
          },
          {
            type: 'p',
            text: 'Nossa visão é ser uma referência confiável em Direito Médico, Direito da Saúde e Direito Digital, reconhecida por nossa atuação ética, humana e inovadora. Buscamos contribuir ativamente para a justiça e o bem-estar de nossos clientes, protegendo seus direitos em áreas distintas e vitais para a sociedade. Nosso objetivo é consolidar nossa reputação como um escritório que oferece soluções jurídicas eficientes, focadas na necessidade real de cada cliente, sem perder a sensibilidade e o compromisso com a justiça.',
          },
        ],
      },
    ],
  },
  saude: {
    title: 'Direito da Saúde',
    key: 'carreon_saude',
    sections: [
      {
        title: 'Proteção Integral para Pacientes em Todo o Brasil',
        key: 'carreon_protecao',
        subtitle: null,
        paragraphs: [
          {
            type: 'p',
            text: 'O Carreon Advocacia é referência nacional na defesa dos direitos à saúde, garantindo que pacientes em todo o Brasil tenham acesso aos tratamentos e cuidados de que necessitam, seja pelo SUS ou planos de saúde privados.',
          },
          {
            type: 'p',
            text: 'Atuamos de forma incisiva em casos de negativas de cobertura, atrasos na liberação de procedimentos e fornecimento de medicamentos vitais, judicializando quando necessário.',
          },
        ],
      },
      {
        title: 'Judicialização da Saúde',
        key: 'carreon_judicializacao',
        subtitle: null,
        paragraphs: [
          {
            type: 'p',
            text: 'Milhares de brasileiros enfrentam diariamente negativas de tratamento. Nosso escritório se especializou em assegurar que os planos de saúde e o SUS cumpram suas obrigações legais, garantindo o acesso imediato a cirurgias de alta complexidade, medicamentos inovadores e tratamentos urgentes.',
          },
          {
            type: 'p',
            text: 'Oferecemos uma atuação estratégica para a obtenção de liminares, assegurando que os direitos dos pacientes sejam respeitados em tempo hábil.',
          },
          {
            type: 'p',
            text: 'Trabalhamos com qualquer causa em que foram negados direitos aos pacientes, como é o caso de negativa para tratamento de doenças graves, autistas e PCDs, cirurgias e procedimentos inovadores, tratamentos oncológicos, cirurgias com a utilização de robótica, home care e internações domiciliares, judicialização de medicamentos de alto custo, como nos casos de quimioterapias orais e cannabis medicinal, exames genéticos e cirurgias para tratamento de ceratocone.',
          },
          {
            type: 'p',
            text: 'Nosso escritório atua em regime de plantão, atuando de forma rápida e objetiva em casos de urgência e emergência, pois estamos preparados para propor ações emergenciais para garantir o tratamento de urgência em casos vitais. A prioridade é sempre o bem-estar e a proteção da saúde de nossos clientes, assegurando que seus direitos sejam preservados sem demora.',
          },
          {
            type: 'h4',
            text: 'Você ou algum familiar enfrenta negativas de tratamento ou cobranças indevidas? Entre em contato agora para garantir seus direitos de forma ágil e eficaz. Seja qual for o seu problema, estamos prontos para ajudar você a garantir seus direitos com dedicação e eficiência.',
          },
        ],
      },
    ],
  },
  medico: {
    title: 'Direito Médico',
    key: 'carreon_medico',
    sections: [
      {
        title: 'Proteção Jurídica para Médicos, Clínicas e Hospitais',
        key: 'carreon_protecao',
        subtitle: null,
        paragraphs: [
          {
            type: 'p',
            text: 'No Carreon Advocacia, oferecemos um atendimento altamente especializado e personalizado para médicos, clínicas e hospitais. Nossa assessoria abrange desde a advocacia preventiva até a defesa em processos complexos, garantindo segurança jurídica em cada detalhe das operações de nossos clientes.',
          },
          {
            type: 'p',
            text: 'Atuamos com foco total na proteção dos profissionais de saúde, sempre em conformidade com as mais rigorosas normas do setor.',
          },
        ],
      },
      {
        title: 'O que fazemos',
        key: 'carreon_fazemos',
        subtitle: null,
        paragraphs: [
          {
            type: 'card',
            subtitle: 'Advocacia Preventiva e Compliance',
            text: 'Soluções customizadas para revisão de contratos e adequação de prontuários, termos de consentimento e contratos de agendamento, garantindo conformidade e segurança.',
          },
          {
            type: 'card',
            subtitle: 'Consultoria contínua',
            text: 'para assegurar que médicos, clínicas e hospitais estejam atualizados e em conformidade com a legislação sanitária e médica vigente.',
          },
          {
            type: 'card',
            subtitle: 'Treinamento Especializado de Equipes',
            text: 'Oferecemos treinamentos personalizados para equipes médicas e administrativas, capacitando-as a lidar com desafios legais e administrativos, sempre dentro das normas.',
          },
          {
            type: 'card',
            subtitle: 'Documentação Médica Completa e Personalizada',
            text: 'Elaboramos documentos médicos personalizados, garantindo que estejam adequadamente ajustados às normas jurídicas, assegurando uma defesa robusta em possíveis litígios.',
          },
          {
            type: 'card',
            subtitle: 'Defesa Jurídica em Casos de Responsabilidade Civil',
            text: 'Oferecemos defesa técnica e estratégica, utilizando perícias especializadas para proteger a reputação e os direitos de nossos clientes. Representamos médicos perante os Conselhos Regionais e Federal de Medicina (CRM/CFM), sempre com agilidade e eficiência.',
          },
          {
            type: 'card',
            subtitle: 'Recuperação de Crédito e Assistência Financeira',
            text: 'Atuamos de forma estratégica e rápida na recuperação de créditos devidos por pacientes inadimplentes e na cobrança de valores devidos por planos de saúde e pelo SUS, garantindo que nossos clientes sejam remunerados corretamente e dentro dos prazos estabelecidos.',
          },
          {
            type: 'card',
            subtitle: 'Atendimento Especializado e de Plantão',
            text: 'Nosso escritório oferece um atendimento de plantão para ações emergenciais, garantindo uma resposta rápida em casos de urgência, como liminares para garantir o tratamento de pacientes.',
          },
          {
            type: 'h4',
            text: 'O diferencial do Carreon Advocacia está na capacidade de oferecer um atendimento humanizado e ágil, focado na solução dos problemas com eficiência e comprometimento.',
          },
        ],
      },
    ],
  },
  digital: {
    title: 'Direito Digital',
    key: 'carreon_digital',
    sections: [
      {
        title:
          'Assessoria Jurídica Especializada para Consumidores, Empresas e Influenciadores',
        key: 'carreon_assessoria',
        subtitle: null,
        paragraphs: [
          {
            type: 'p',
            text: 'No Carreon Advocacia, oferecemos assessoria jurídica altamente especializada para consumidores, empresas e influenciadores, com foco exclusivo no Direito Digital.',
          },
          {
            type: 'p',
            text: 'Nossa atuação vai além do básico, fornecendo soluções jurídicas diferenciadas para garantir a proteção dos direitos digitais dos nossos clientes, tanto no âmbito administrativo quanto judicial.',
          },
        ],
      },
      {
        title: 'O que fazemos',
        key: 'carreon_fazemos',
        subtitle: null,
        paragraphs: [
          {
            type: 'card',
            subtitle: 'Crimes Cibernéticos',
            text: 'Recuperação e Defesa Jurídica através da atuação junto a plataformas digitais para recuperação rápida de contas invadidas, com soluções administrativas e judiciais.',
          },
          {
            type: 'card',
            subtitle: 'Responsabilização de Invasores',
            text: 'Ações civis e criminais contra infratores, buscando compensação por danos.',
          },
          {
            type: 'card',
            subtitle: 'Reparação de Danos à Reputação',
            text: 'Defesa estratégica para restaurar a imagem afetada por crimes digitais.',
          },
        ],
      },
      {
        title: 'Assessoria Jurídica para Influenciadores Digitais',
        key: 'carreon_influenciadores',
        subtitle: null,
        paragraphs: [
          {
            type: 'card',
            subtitle: 'Contratos Personalizados',
            text: 'Elaboração de contratos robustos com marcas e anunciantes, prevenindo litígios.',
          },
          {
            type: 'card',
            subtitle: 'Orientação em Publicidade Digital',
            text: 'Assessoria sobre as exigências legais e éticas em parcerias e promoções.',
          },
          {
            type: 'card',
            subtitle: 'Proteção de Imagem e Propriedade Intelectual',
            text: 'Criação de políticas de uso de imagem e conteúdo para evitar apropriação indevida e uso não autorizado.',
          },
        ],
      },
      {
        title: 'Conformidade com a LGPD',
        key: 'carreon_lgpd',
        subtitle: null,
        paragraphs: [
          {
            type: 'card',
            subtitle: 'Consultoria Especializada',
            text: 'Adequação completa às normas da Lei Geral de Proteção de Dados (LGPD), prevenindo sanções legais.',
          },
          {
            type: 'card',
            subtitle: 'Defesa em Casos de Violação',
            text: 'Representação jurídica em processos por violação de privacidade, minimizando impactos financeiros e à reputação.',
          },
        ],
      },
      {
        title: 'Resolução de Litígios Digitais',
        key: 'carreon_litigios',
        subtitle: null,
        paragraphs: [
          {
            type: 'card',
            subtitle: 'Ações Contra Plataformas Digitais',
            text: 'Movemos ações judiciais ou administrativas para garantir a recuperação de contas suspensas ou encerradas injustamente.',
          },
          {
            type: 'card',
            subtitle: 'Mediação e Conciliação',
            text: 'Sempre que possível, buscamos acordos extrajudiciais para resolução de disputas digitais.',
          },
        ],
      },
    ],
  },
  // contato: {
  //   title: 'Contato',
  //   key: 'carreon_contato',
  //   sections: [
  //     {
  //       title: 'Entre em Contato com o Carreon Advocacia',
  //       subtitle: null,
  //       paragraphs: [
  //         {
  //           type: 'p',
  //           text: 'Você está enfrentando negativas de tratamento por parte do seu plano de saúde ou dificuldades com o SUS?',
  //         },
  //         {
  //           type: 'p',
  //           text: 'Precisa de ajuda jurídica para lidar com processos éticos, responsabilidade civil médica ou disputas contratuais em seu consultório, clínica ou hospital?',
  //         },
  //         {
  //           type: 'p',
  //           text: 'Tem dúvidas sobre a organização da documentação, revisão de prontuários ou confecção de documentos médicos, como contratos e termos de consentimento, para garantir que seu consultório, clínica ou hospital esteja operando de forma segura e dentro da lei?',
  //         },
  //         {
  //           type: 'p',
  //           text: 'Ou você é consumidor, influenciador digital ou uma empresa que está enfrentando problemas com invasões de contas nas redes sociais, uso indevido de sua imagem ou conteúdo, ou deseja adequar-se às exigências da LGPD?',
  //         },
  //         {
  //           type: 'p',
  //           text: 'Não espere o problema se agravar. Entre em contato agora para receber um atendimento jurídico rápido e eficiente, especializado em Direito Médico e Direito da Saúde e Direito Digital!',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Formulário de Contato',
  //       key: 'carreon_formulario',
  //       subtitle: null,
  //       paragraphs: [
  //         {
  //           type: 'form',
  //           fields: [
  //             {
  //               type: 'text',
  //               label: 'Nome Completo',
  //               name: 'nome',
  //               required: true,
  //             },
  //             {
  //               type: 'email',
  //               label: 'E-mail',
  //               name: 'email',
  //               required: true,
  //             },
  //             {
  //               type: 'tel',
  //               label: 'Telefone',
  //               name: 'telefone',
  //               required: true,
  //             },
  //             {
  //               type: 'text',
  //               label: 'Assunto',
  //               name: 'assunto',
  //               required: true,
  //             },

  //             {
  //               type: 'textarea',
  //               label: 'Mensagem',
  //               name: 'mensagem',
  //               required: true,
  //             },
  //             {
  //               type: 'select',
  //               label: 'Preferência de Contato',
  //               name: 'preferencia',
  //               options: ['E-mail', 'Telefone', 'WhatsApp'],
  //               required: true,
  //             },
  //             {
  //               type: 'checkbox',
  //               label:
  //                 'Declaro que li e concordo com os termos da Política de Privacidade.',
  //               name: 'politica',
  //               required: true,
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Outros Canais de Contato',
  //       key: 'carreon_canais',
  //       subtitle: null,
  //       paragraphs: [
  //         {
  //           type: 'p',
  //           text: 'Além do formulário de contato, você pode falar diretamente com nossa equipe pelos seguintes canais:',
  //         },
  //         {
  //           type: 'p',
  //           text: 'E-mail: contato@carreon.com.br',
  //         },
  //         {
  //           type: 'p',
  //           text: 'Telefone: (11) 99195-7391',
  //         },
  //         {
  //           type: 'p',
  //           text: 'WhatsApp: (11) 99195-7391',
  //         },
  //         {
  //           type: 'p',

  //           text: 'Endereço para consultas presenciais: Avenida Paulista, uma das áreas mais nobres da cidade de São Paulo (mediante agendamento e pagamento de consulta de forma antecipada).',
  //         },
  //         {
  //           type: 'p',
  //           text: 'Nosso compromisso é garantir que seu atendimento seja rápido e eficiente, independentemente da forma de contato escolhida.',
  //         },
  //         {
  //           type: 'p',
  //           text: 'Não adie a solução dos seus problemas jurídicos. Estamos aqui para ajudar você a garantir seus direitos de forma rápida e segura.',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Proteção de Dados e LGPD',
  //       key: 'carreon_lgpd',
  //       subtitle: null,
  //       paragraphs: [
  //         {
  //           type: 'p',
  //           text: 'No Carreon Advocacia, tratamos sua privacidade com seriedade. As informações fornecidas serão usadas exclusivamente para atender à sua solicitação jurídica e serão armazenadas apenas pelo tempo necessário. Em conformidade com a LGPD, você pode solicitar a exclusão dos seus dados a qualquer momento.',
  //         },
  //         {
  //           type: 'p',
  //           text: 'Para mais informações, consulte nossa Política de Privacidade.',
  //         },
  //       ],
  //     },
  //   ],
  // },
 
};

export { conf, menu, content };
