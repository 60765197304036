import {
  FaRegCalendarAlt,
  FaRegCalendarPlus,
  FaWhatsapp,
  FaInstagram,
  FaLinkedinIn,
  FaRegFile,
  FaRegStar,
  FaUserLock,
} from 'react-icons/fa';

import {
  LiaTelegramPlane,
  LiaUser,
  LiaUserFriendsSolid,
  LiaUsersCogSolid,
} from 'react-icons/lia';

import {
  FcPhone,
  FcPlus,
  FcDownload,
  FcUpload,
  FcAddDatabase,
  FcCheckmark,
  FcClock,
  FcOpenedFolder,
} from 'react-icons/fc';

import {
  MdExpand,
  MdOutlineManageSearch,
  MdAddCircleOutline,
  MdOutlineRemoveCircleOutline,
  MdOutlineAttachFile,
  MdOutlineUploadFile,
  MdMenu,
  MdCopyright,
} from 'react-icons/md';

import {
  TbFileInvoice,
  TbStarFilled,
  TbLetterF,
  TbLetterS,
} from 'react-icons/tb';

import {
  AiOutlineAppstore,
  AiOutlineDashboard,
  AiOutlineLogout,
  AiOutlineProduct,
  AiOutlineUser,
  AiOutlineUserAdd,
  AiOutlineUserDelete,
} from 'react-icons/ai';

import {
  IoCheckmarkDoneOutline,
  IoCloseSharp,
  IoSettingsOutline,
} from 'react-icons/io5';

import {
  PiFilePdfFill,
  PiImageFill,
  PiCurrencyCircleDollarFill,
} from 'react-icons/pi';

import { FaPhone, FaCircleCheck, FaHouseChimney } from 'react-icons/fa6';

import { GoMail, GoTasklist } from 'react-icons/go';

import { RiMenuAddLine, RiArrowGoBackFill } from 'react-icons/ri';
import { BiMessageSquareEdit, BiSolidSave, BiRegistered } from 'react-icons/bi';
import { BsTrash3Fill, BsCashCoin } from 'react-icons/bs';

import { GiSandsOfTime } from 'react-icons/gi';

import { RxCross2 } from 'react-icons/rx';

import { VscDebugStart, VscSignOut } from 'react-icons/vsc';

import { FiEdit } from 'react-icons/fi';

import { CgSearchLoading } from 'react-icons/cg';

import { GrUpdate } from 'react-icons/gr';

import { HiMiniAdjustmentsHorizontal } from 'react-icons/hi2';

import { ui } from '../config/ui';

export default function IconsUI({
  info,
  clsnm = '',
  click = null,
  tooltip = '',
}) {
  switch (info) {
    case ui.letter.f:
      return (
        <TbLetterF
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.letter.s:
      return (
        <TbLetterS
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
      case ui.social.linkedin:
        return (
          <FaLinkedinIn
            data-toggle='tooltip'
            data-placement='top'
            title={tooltip}
            className={clsnm}
            onClick={click}
          />
        );
    case ui.social.telegram:
      return (
        <LiaTelegramPlane
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.social.whatsapp:
      return (
        <FaWhatsapp
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.social.instagram:
      return (
        <FaInstagram
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.social.email:
      return (
        <GoMail
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.social.calendar.add:
      return (
        <FaRegCalendarPlus
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.social.calendar.reg:
      return (
        <FaRegCalendarAlt
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.social.phone.color:
      return (
        <FcPhone
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.social.phone.reg:
      return (
        <FaPhone
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.social.message:
      return (
        <BiMessageSquareEdit
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.action.save:
      return (
        <BiSolidSave
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.action.close:
      return (
        <IoCloseSharp
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.action.back:
      return (
        <RiArrowGoBackFill
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.action.edit:
      return (
        <FiEdit
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.action.delete.trash:
      return (
        <BsTrash3Fill
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.action.delete.circle:
      return (
        <MdOutlineRemoveCircleOutline
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.action.add.reg:
      return (
        <RiMenuAddLine
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.action.add.color:
      return (
        <FcPlus
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.action.add.circle:
      return (
        <MdAddCircleOutline
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.action.expand:
      return (
        <MdExpand
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.action.search:
      return (
        <MdOutlineManageSearch
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.action.magnifier:
      return (
        <CgSearchLoading
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.action.upload.color:
      return (
        <FcUpload
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.action.upload.reg:
      return (
        <MdOutlineUploadFile
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.action.download:
      return (
        <FcDownload
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.action.update:
      return (
        <GrUpdate
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.action.start:
      return (
        <VscDebugStart
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.folder.open:
      return (
        <FcOpenedFolder
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.file.pdf:
      return (
        <PiFilePdfFill
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.file.image:
      return (
        <PiImageFill
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.file.other:
      return (
        <FaRegFile
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.file.attach:
      return (
        <MdOutlineAttachFile
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.status.waiting:
      return (
        <GiSandsOfTime
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.status.done:
      return (
        <IoCheckmarkDoneOutline
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.user.reg:
      return (
        <AiOutlineUser
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.user.add:
      return (
        <AiOutlineUserAdd
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.user.delete:
      return (
        <AiOutlineUserDelete
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.user.lock:
      return (
        <FaUserLock
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.misc.cross:
      return (
        <RxCross2
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.misc.check.color:
      return (
        <FcCheckmark
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.misc.check.circle:
      return (
        <FaCircleCheck
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.misc.clock:
      return (
        <FcClock
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.misc.add_database:
      return (
        <FcAddDatabase
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.misc.edit:
      return (
        <FiEdit
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.misc.tasks:
      return (
        <GoTasklist
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.misc.star:
      return (
        <TbStarFilled
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.misc.starlight:
      return (
        <FaRegStar
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.misc.house:
      return (
        <FaHouseChimney
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.misc.money:
      return (
        <PiCurrencyCircleDollarFill
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.misc.menu:
      return (
        <MdMenu
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.misc.config:
      return (
        <IoSettingsOutline
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.misc.logout:
      return (
        <AiOutlineLogout
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.misc.dash:
      return (
        <AiOutlineDashboard
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.misc.products:
      return (
        <AiOutlineProduct
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.misc.app:
      return (
        <AiOutlineAppstore
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.misc.adjust:
      return (
        <HiMiniAdjustmentsHorizontal
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.misc.registered:
      return (
        <BiRegistered
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.misc.copyright:
      return (
        <MdCopyright
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
      case ui.misc.doorout:
        return (
          <VscSignOut
            data-toggle='tooltip'
            data-placement='top'
            title={tooltip}
            className={clsnm}
            onClick={click}
          />
        );
    case ui.money.payment:
      return (
        <BsCashCoin
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.money.invoice:
      return (
        <TbFileInvoice
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.money.subscriber:
      return (
        <LiaUsersCogSolid
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.user.single:
      return (
        <LiaUser
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.user.multi:
      return (
        <LiaUserFriendsSolid
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    default:
      return '';
  }
}

const fileIcon = (type) => {
  if (type.includes('image'))
    return IconsUI({ info: ui.file.image, clsnm: 'svg09 fc-green' });
  if (type.includes('pdf'))
    return IconsUI({ info: ui.file.pdf, clsnm: 'svg09 fc-red' });
  return IconsUI({ info: ui.file.other, clsnm: 'svg09 fc-blur' });
};

export { fileIcon, ui };
