export const ui = {
  social: {
    whatsapp: 'whatsapp',
    instagram: 'instagram',
    telegram: 'telegram',
    email: 'email',
    linkedin: 'linkedin',
    calendar: {
      reg: 'calendar',
      add: 'calendar-add',
    },
    phone: {
      reg: 'phone',
      color: 'phone-color',
    },
    message: 'message',
  },
  action: {
    edit: 'edit',
    delete: {
      trash: 'delete-trash',
      circle: 'delete-circle',
    },
    add: {
      color: 'add-color',
      reg: 'add-reg',
      circle: 'add-circle',
    },
    expand: 'expand',
    download: 'download',
    upload: {
      color: 'upload-color',
      reg: 'upload-reg',
    },
    magnifier: 'magnifier',
    start: 'start',
    search: 'search',
    back: 'back',
    save: 'save',
    update: 'update',
    close: 'close',
  },
  folder: {
    open: 'open',
  },
  file: {
    pdf: 'pdf',
    image: 'image',
    other: 'other',
    attach: 'attach',
  },
  status: {
    waiting: 'waiting',
    done: 'done',
  },

  user: {
    reg: 'user',
    add: 'user-add',
    delete: 'user-delete',
    single: 'single',
    multi: 'multi',
    lock: 'lock',
  },
  misc: {
    cross: 'cross',
    check: {
      color: 'check',
      circle: 'check-circle',
    },
    clock: 'clock',
    edit: 'edit',
    add_database: 'add-database',
    tasks: 'tasks',
    star: 'star',
    starlight: 'starlight',
    house: 'house',
    money: 'money',
    menu: 'menu',
    logout: 'logout',
    products: 'products',
    dash: 'dash',
    app: 'app',
    adjust: 'adjust',
    registered: 'registered',
    copyright: 'copyright',
    doorout: 'doorout',
  },
  letter: {
    f: 'f',
    s: 's',
  },
  money: {
    reg: 'money',
    color: 'money-color',
    payment: 'payment',
    invoice: 'invoice',
    subscriber: 'subscriber',
  },
};
