import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { conf, GlobalContext, api, endpoint } from '../config/imports';

export default function GlobalProvider({ children }) {
  const [section, setSection] = useState('home');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  async function createLead({ name, email, phone, message }) {
    let response = await api.post(endpoint.leads.create, {
      name,
      email,
      phone,
      message,
    });
    return response;
  }

  function store() {
    return {
      token: sessionStorage.getItem('@App:token'),
      email: sessionStorage.getItem('@App:mail'),
      nome: sessionStorage.getItem('@App:user'),
      codigo: sessionStorage.getItem('@App:codigo'),
      role: sessionStorage.getItem('@App:role'),
      apoio: JSON.parse(sessionStorage.getItem('@App:apoio')),
    };
  }

  function checkLogin() {
    const { token, email, nome } = store();
    if (!token || !email || !nome) {
      return false;
    }
    return true;
  }

  async function login(userData) {
    const removeAspas = (str) => {
      return str.replace(/['"]+/g, '');
    };
    const response = await api.post(endpoint.login, userData, false);
    const { success } = response.data;

    if (success) {
      const { codigo, email, token, user } = response.data;
      sessionStorage.setItem('@App:token', removeAspas(JSON.stringify(token)));
      sessionStorage.setItem('@App:user', removeAspas(JSON.stringify(user)));
      sessionStorage.setItem('@App:mail', removeAspas(JSON.stringify(email)));
      sessionStorage.setItem(
        '@App:codigo',
        removeAspas(JSON.stringify(codigo)),
      );
    }
    return response;
  }

  async function logout(full = 0) {
    const token = sessionStorage.getItem('@App:token');
    await api.post(
      endpoint.logout,
      {
        token,
        full,
      },
      false,
    );
    sessionStorage.clear();
    localStorage.clear();
  }

  async function userData() {
    const response = await api.post(endpoint.user, {}, false);
    return response.data;
  }

  const context = {
    conf,
    section,
    setSection,
    loading,
    setLoading,
    navigate,
    store,
    checkLogin,
    login,
    logout,
    userData,

    createLead,
  };

  return (
    <GlobalContext.Provider value={context}>{children}</GlobalContext.Provider>
  );
}
