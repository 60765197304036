const base = 'https://g3c32193a94f6bf-bewook.adb.sa-saopaulo-1.oraclecloudapps.com/ords/carreon/api/';
const host= 'https://g3c32193a94f6bf-bewook.adb.sa-saopaulo-1.oraclecloudapps.com/ords/bwhost/api/';

const endpoint = {
  leads: {
    create: `leads/c/`,
    read: `leads/r/`,
    delete: `leads/d/`,
  },
  login: `login/`,
  logout: `logout/`,
}

export {
  base, host, endpoint
}